import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import MicroblogElement from "../components/microblog"

const Lab = () => {
  const data = useStaticQuery(graphql`
    {
      allMdx(
        sort: { order: DESC, fields: [frontmatter___date] }
        filter: { frontmatter: { type: { eq: "microblog" } } }
      ) {
        nodes {
          frontmatter {
            date
            title
            slug
            type
          }
          body
          slug
          id
        }
      }
    }
  `)

  // Array of all news articles
  const allNews = data.allMdx.nodes

  // State for the list
  const [list, setList] = useState([...allNews.slice(0, 10)])

  // State to trigger oad more
  const [loadMore, setLoadMore] = useState(false)

  // State of whether there is more to load
  const [hasMore, setHasMore] = useState(allNews.length > 10)

  // Load more button click
  const handleLoadMore = () => {
    setLoadMore(true)
  }

  // Handle loading more articles
  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length
      const isMore = currentLength < allNews.length
      const nextResults = isMore
        ? allNews.slice(currentLength, currentLength + 10)
        : []
      setList([...list, ...nextResults])
      setLoadMore(false)
    }
  }, [loadMore, hasMore]) //eslint-disable-line

  //Check if there is more
  useEffect(() => {
    const isMore = list.length < allNews.length
    setHasMore(isMore)
  }, [list]) //eslint-disable-line

  return (
    <div className="lab">
      <div className="wrapper">
        <div className="back-button">⟵ Go back</div>
        <div className="heading">Lab3</div>
        <div className="des">
          This is the home to a couple web experiemnts I'm running.
        </div>
      </div>
    </div>
  )
}

export default Lab
